<template>
    <div class="page page-paying">
        <p class="tips">您的测评报告已经生成，<br>支付完成后将自动进入报告查看页面。</p>
        <div class="section">
            <div class="money">
                <span class="price">新用户限时专享优惠价：<b>{{ orderAmount }}元</b></span>
                <del>原价：299.00元</del>
            </div>
            <div class="countdown">
                <span class="time">{{ times[0] }}</span>
                <span class="text">分</span>
                <span class="time">{{ times[1] }}</span>
                <span class="text">秒</span>
                <span class="time">{{ times[2] }}</span>
                <span class="text">后失效</span>
            </div>
            <p class="notes">
                <strong>98% 的测过的人觉得很准</strong><br>
                付费后，您的专业测评报告将安全的永久保存供您随时查看。
            </p>
        </div>
        <div class="pay_channels">
            <a class="pay_channels__item pay_channels__item--Weixin" :href="weixinPayUrl"
                v-if="weixinPayUrl">微信支付</a>
            <a class="pay_channels__item pay_channels__item--Alipay" :href="alipayPayUrl"
                v-if="alipayPayUrl">支付宝支付</a>
        </div>
        <div class="section">
            <strong class="section__heading">测试后您将获得</strong>
            <div class="reports">
                <div class="reports__item">
                    <strong>结果分析</strong>
                    <div class="lock">
                        <img src="@/assets/images/lock_report_1.png">
                        <div class="lock-icon"><img src="@/assets/images/lock_icon.png"></div>
                    </div>
                </div>
                <div class="reports__item">
                    <strong>维度分析</strong>
                    <div class="lock">
                        <img src="@/assets/images/lock_report_2.png">
                        <div class="lock-icon"><img src="@/assets/images/lock_icon.png"></div>
                    </div>
                </div>
                <div class="reports__item">
                    <strong>类型分析</strong>
                    <div class="lock">
                        <img src="@/assets/images/lock_report_3.png">
                        <div class="lock-icon"><img src="@/assets/images/lock_icon.png"></div>
                    </div>
                </div>
                <div class="reports__item">
                    <strong>优劣势分析</strong>
                    <div class="lock">
                        <img src="@/assets/images/lock_report_4.png">
                        <div class="lock-icon"><img src="@/assets/images/lock_icon.png"></div>
                    </div>
                </div>
                <div class="reports__item">
                    <strong>自我发展建议</strong>
                    <div class="lock">
                        <img src="@/assets/images/lock_report_5.png">
                        <div class="lock-icon"><img src="@/assets/images/lock_icon.png"></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section">
            <strong class="section__heading">付费解锁后，你可以享受一下权益</strong>
            <div class="benefits">
                <div class="benefits__item">
                    <img src="@/assets/images/benefit_1.png">
                    <span class="text">测评结果报告</span>
                </div>
                <div class="benefits__item">
                    <img src="@/assets/images/benefit_2.png">
                    <span class="text">指标要点详解</span>
                </div>
                <div class="benefits__item">
                    <img src="@/assets/images/benefit_3.png">
                    <span class="text">专家指导建议</span>
                </div>
                <div class="benefits__item">
                    <img src="@/assets/images/benefit_4.png">
                    <span class="text">永久安全保存</span>
                </div>
            </div>
        </div>
        <div class="safe_icons">
            <img src="@/assets/images/safe_icon_1.png">
            <img src="@/assets/images/safe_icon_2.png">
            <img src="@/assets/images/safe_icon_3.png">
        </div>
        <div class="actions">
            <div class="exit" @click="handleExit">退出</div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Pay',
    data() {
        return {
            times: [29, 59, 99],
            countdownIntervalId: null,
            orderAmount: null,
            weixinPayUrl: null,
            alipayPayUrl: null,
            checkPayIntervalId: null
        }
    },
    mounted() {
        this.newPay()
    },
    beforeDestroy() {
        this.stopCheckPay()
        this.stopCountdown()
    },
    methods: {
        isWeChatBrowser() {
            const userAgent = navigator.userAgent.toLowerCase()
            return userAgent.indexOf('micromessenger') > -1
        },
        startCountdown() {
            if (this.countdownIntervalId == null) {
                this.countdownIntervalId = setInterval(() => {
                    if (this.times[2] > 0) {
                        this.$set(this.times, 2, this.times[2] - 1)
                    } else if (this.times[1] > 0) {
                        this.$set(this.times, 2, 99)
                        this.$set(this.times, 1, this.times[1] - 1)
                    } else if (this.times[0] > 0) {
                        this.$set(this.times, 1, 59)
                        this.$set(this.times, 0, this.times[0] - 1)
                    } else {
                        this.$set(this.times, 0, 29)
                    }
                }, 10)
            }
        },
        stopCountdown() {
            if (this.countdownIntervalId != null) {
                clearInterval(this.countdownIntervalId)
                this.countdownIntervalId = null
            }
        },
        checkPay() {
            this.$api.post('/pay/paid', {
                order_no: this.$route.query.order_no
            }).then(res => {
                if (res.data.code === 0) {
                    if (res.data.data.is_paid) {
                        this.paySuccess()
                    }
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        startCheckPay() {
            this.checkPayIntervalId = setInterval(() => {
                this.checkPay()
            }, 2000)
        },
        paySuccess() {
            let path = 'result'
            if (!this.isWeChatBrowser()) {
                path = 'success'
            }
            this.$router.replace({
                path: path,
                query: {
                    order_no: this.$route.query.order_no
                }
            })
        },
        stopCheckPay() {
            if (this.checkPayIntervalId != null) {
                clearInterval(this.checkPayIntervalId)
                this.checkPayIntervalId = null
            }
        },
        newPay() {
            let returnUrl = window.location.href
            const isPaymentRedirect = returnUrl.indexOf('#payment_redirect') !== -1
            if (!isPaymentRedirect) {
                returnUrl += '#payment_redirect'
            }
            this.$api.post('/pay', {
                order_no: this.$route.query.order_no,
                pay_channel_code: this.$route.query.pay_channel_code,
                return_url: returnUrl
            }).then(res => {
                if (res.data.code === 0) {
                    if (res.data.data.is_paid) {
                        this.paySuccess()
                    } else {
                        this.orderAmount = res.data.data.order_amount
                        const payUrl = res.data.data.pay_url;
                        if (this.$route.query.pay_channel_code == 'Weixin') {
                            this.weixinPayUrl = payUrl
                        } else if (this.$route.query.pay_channel_code == 'Alipay') {
                            this.alipayPayUrl = payUrl
                        }
                        if (!isPaymentRedirect) {
                            window.location.href = payUrl
                        } else {
                            this.startCountdown()
                        }
                        this.startCheckPay()
                    }
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        handleExit() {
            this.$router.replace('/')
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/paying.scss';
</style>
