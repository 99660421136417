<template>
    <div class="page page--index">
        <Header :back="false" :title="typeInfo.title"></Header>
        <div class="page__content" v-if="step === 1">
            <div class="question">
                <div class="progress">
                    <div class="progress-bar">
                        <div class="progress-bar__out">
                            <span class="progress-bar__in"
                                :style="{ width: (questionsNo / questions.length) * 100.0 + '%' }"></span>
                        </div>
                    </div>
                    <div class="progress-text"><b>{{ questionsNo }}</b>/{{ questions.length }}题</div>
                </div>
                <div :class="{ question__title: true, 'question__title--hide': isHide }">{{ questionsNo }}.{{ question.question }}</div>
                <div class="answers" :class="{ answers: true, 'answers--hide': isHide }">
                    <div v-for=" (item, i) in question.answers" :key="i"
                        :class="{ 'answers__item': true, 'answers__item--active': questions[questionsNo - 1].answersIndex === i }"
                        @click="handleQuestionItem(i)" :tabindex="i">
                        <div class="answer">{{ item.text }}</div>
                    </div>
                </div>
                <div class="actions">
                    <div :class="{ actions__button: true, 'actions__button--prev': true, 'actions__button--hide': questionsNo == 1}" @click="prevQuestion">上一题</div>
                    <div :class="{ actions__button: true, 'actions__button--next': true, 'actions__button--hide': (questions[questionsNo - 1].answersIndex == -1 || isHide || !isHandle)}" @click="nextQuestion">下一题</div>
                </div>
            </div>
            <p class="footer">
                {{ typeInfo.notes }}
                <small v-if="webInfo">
                    <br>{{ webInfo.company_name }}
                    <br>{{ webInfo.icp }}
                </small>
            </p>
        </div>
        <div class="page__content" v-else-if="step === 2">
            <dl>
                <dt>性别</dt>
                <dd>
                    <div class="genders">
                        <div v-for="(gender, key) in genders" :key="key"
                            :class="{ genders__item: true, 'genders__item--active': gendersKey == key }"
                            @click="handleGendersItem(key)">
                            {{ gender }}
                            <img :src="require('@/assets/images/gender_' + key + '.png')">
                        </div>
                    </div>
                </dd>
                <dt>年龄</dt>
                <dd>
                    <div class="ages">
                        <div v-for="(value, key) in ages" :key="key"
                            :class="{ ages__item: true, 'ages__item--active': agesKey == key }"
                            @click="handleAgesItem(key)">{{ value }}</div>
                    </div>
                </dd>
            </dl>
            <small>请务必选择<i>真实性别和年龄</i>这将决定本次测评的计算准确度。</small>
            <div class="commit">
                <button type="button" @click="handleSubmit">下一步</button>
            </div>
        </div>
    </div>
</template>

<script>
import Header from '../components/Header.vue'

export default {
    name: 'Index',
    components: {
        Header
    },
    data() {
        return {
            step: 1,
            beginTime: null,
            completeTime: null,
            question: {},
            isHide: false,
            isHandle: true,
            questionsNo: 0,
            questions: [],
            webInfo: {},
            typeInfo: {},
            data: [],
            genders: {
                '1': '男生',
                '2': '女生'
            },
            gendersKey: 0,
            ages: [
                '18-20岁',
                '21-30岁',
                '31-40岁',
                '41-50岁',
                '51-60岁',
                '60岁以上',
            ],
            agesKey: -1
        }
    },
    mounted() {
        this.fetchData()
    },
    methods: {
        fetchData() {
            this.$api.post('/question/list', {
                'type': this.$route.query.type || 1,
                'logid_url': window.location.href
            }).then(res => {
                if (res.data.code === 0) {
                    this.questions = res.data.data.questions
                    for (let i = 0; i < this.questions.length; i++) {
                        this.$set(this.questions[i], 'answersIndex', -1)
                    }
                    this.webInfo = res.data.data.web_info
                    this.typeInfo = res.data.data.type_info
                    document.title = this.typeInfo.title
                    this.questionsIndex = 0
                    this.nextQuestion()
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        },
        prevQuestion() {
            if (this.questionsNo <= 1) {
                return;
            }
            this.questionsNo--;
            this.question = this.questions[this.questionsNo - 1]
        },
        nextQuestion() {
            if (this.questionsNo >= 1 && (this.questions[this.questionsNo - 1].answersIndex == -1)) {
                alert('请选择答案');
                return;
            }
            if (this.questionsNo >= this.questions.length) {
                this.completeTime = new Date().getTime()
                this.$set(this, 'step', 2)
                return;
            }
            this.questionsNo++
            this.question = this.questions[this.questionsNo - 1]
            this.isHide = false
            setTimeout(() => {
                this.isHandle = true
            }, 400);
        },
        handleQuestionItem(i) {
            if (!this.isHandle) {
                return;
            }
            this.isHandle = false
            if (this.questionsNo == 1 && this.beginTime == null) {
                this.beginTime = new Date().getTime()
            }
            this.$set(this.questions[this.questionsNo - 1], 'answersIndex', i)
            this.isHide = true
            setTimeout(() => {
                this.nextQuestion()
            }, 800);
        },
        handleGendersItem(key) {
            this.gendersKey = key
        },
        handleAgesItem(key) {
            this.agesKey = key
        },
        handleSubmit() {
            let questions = []
            for (var i = 0; i < this.questions.length; i++) {
                const question = this.questions[i];
                questions.push({
                    category_id: question.category_id,
                    question: question.question,
                    answer: question.answers[question.answersIndex]
                })
            }
            const durationSeconds = Math.floor((this.completeTime - this.beginTime) / 1000)
            const completedAt = Math.floor(this.completeTime / 1000)
            this.$api.post('/order/create', {
                'type': this.$route.query.type || 1,
                'questions': questions,
                'gender': this.gendersKey,
                'age':  (this.agesKey != -1 ? this.ages[this.agesKey] : null),
                'duration_seconds': durationSeconds,
                'completed_at': completedAt,
                'logid_url': window.location.href
            }).then(res => {
                if (res.data.code === 0) {
                    this.$router.push({
                        path: 'pay',
                        query: {
                            order_no: res.data.data.order_no
                        }
                    })
                } else {
                    alert(res.data.message)
                }
            }).catch(error => {
                alert(error)
            }).finally(() => {
            })
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/sass/index.scss';
</style>
